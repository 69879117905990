<template>
  <center-screen>
    <p v-if="!code">
      Thank you <strong>{{ this.$route.params.name }}</strong> for signing up
      with us, to complete account registration please go to your
      <strong>{{ this.$route.params.email }}</strong> email inbox and click on
      confirm button.
    </p>
    <p v-if="!!code">
      Thank you <strong>{{ $route.params.name }}</strong> for signing up with
      us.
    </p>
    <p v-if="invalid">
      Your activatoion code is invalid, please make sure the activation code on
      the url is correct or contact system support.
    </p>
    <div class="text-center ma-5">
      <v-progress-circular
        v-if="!invalid"
        :size="40"
        color="primary"
        :indeterminate="!complete"
      ></v-progress-circular>
      <v-icon large v-if="invalid" color="red">mdi-alert-circle</v-icon>
    </div>

    <template v-if="complete" v-slot:actions>
      <v-spacer />
      <v-btn color="primary" tile depressed @click="goToHome()">
        Login
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions } from "vuex";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Login",
  components: { CenterScreen },
  data() {
    return {
      redirect: "/",
      username: null,
      email: null,
      code: null,
      processing: true,
      complete: false,
      invalid: false,
    };
  },
  mounted() {
    const { username, email, code } = this.$route.params;
    this.username = username;
    this.email = email;
    if (code) {
      this.code = code;
      this.completeRegistration({ code, type: "email" })
        .then(() => {
          this.complete = true;
          this.$router.push({ name: "login" });
        })
        .catch(() => {
          this.invalid = true;
        });
    }
  },
  methods: {
    ...mapActions("auth", ["login", "completeRegistration"]),

    goToHome() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
